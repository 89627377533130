import { PageHeader } from '~/core/ui/Page'
import MobileAppNavigation from '~/components/MobileNavigation'

const AppHeader: React.FC<{
  title?: string | React.ReactNode
  description?: string | React.ReactNode
  breadcrumbs?: {
    title: string;
    link?: string;
  }[]
  children?: React.ReactNode
}> = ({ title, description, children, breadcrumbs }) => {
  return (
    <PageHeader
      title={title}
      description={description}
      breadcrumbs={breadcrumbs}
      mobileNavigation={<MobileAppNavigation />}
    >
      {children}
    </PageHeader>
  )
}

export default AppHeader
