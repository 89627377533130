import { AudioLinesIcon, BotIcon, BrainIcon, ChartBarIcon, Database, HomeIcon, LayoutGridIcon, SquareCodeIcon, UserIcon, UsersIcon } from 'lucide-react'

import configuration from '~/configuration'

interface Divider {
  divider: true
}

export interface NavigationItemLink {
  label: string
  path: string
  Icon: (props: { className: string }) => JSX.Element
  end?: boolean
  development?: boolean
  orgs?: number[]
  hideFromOrgs?: number[]
}

interface NavigationGroup {
  label: string
  collapsible?: boolean
  collapsed?: boolean
  children: NavigationItemLink[]
}

export type NavigationItem = NavigationItemLink | NavigationGroup | Divider

interface NavigationConfig {
  items: NavigationItem[]
}

const NAVIGATION_CONFIG: NavigationConfig = {
  items: [
    {
      label: 'Home',
      path: configuration.paths.appHome,
      Icon: ({ className }: { className: string }) => {
        return <HomeIcon className={className} />
      },
      end: true,
    },
    {
      label: 'Agents',
      path: '/agents',
      orgs: [9, 27],
      Icon: ({ className }: { className: string }) => {
        return <BotIcon className={className} />
      },
      end: false,
    },
    {
      label: 'Knowledge Base',
      path: '/knowledge',
      orgs: [9],
      Icon: ({ className }: { className: string }) => {
        return <Database className={className} />
      },
      end: false,
    },
    {
      label: 'Transcribe',
      path: '/transcribe',
      hideFromOrgs: [27],
      Icon: ({ className }: { className: string }) => {
        return <AudioLinesIcon className={className} />
      },
      end: false,
    },
    {
      label: 'common:coding:tabLabel',
      path: '/coding',
      hideFromOrgs: [27],
      Icon: ({ className }: { className: string }) => {
        return <SquareCodeIcon className={className} />
      },
      end: false,
    },
    {
      label: 'AI Researcher',
      path: '/research',
      Icon: ({ className }: { className: string }) => {
        return <BrainIcon className={className} />
      },
      end: false,
    },
    {
      label: 'Quant',
      orgs: [9],
      hideFromOrgs: [27],
      path: '/quant',
      Icon: ({ className }: { className: string }) => {
        return <ChartBarIcon className={className} />
      },
      end: true,
    },

    {
      label: 'common:settingsTabLabel',
      collapsible: false,
      children: [
        {
          label: 'common:profileSettingsTabLabel',
          path: configuration.paths.settings.profile,
          Icon: ({ className }: { className: string }) => {
            return <UserIcon className={className} />
          },
        },
        {
          label: 'common:organizationSettingsTabLabel',
          path: configuration.paths.settings.organization,
          Icon: ({ className }: { className: string }) => {
            return <UsersIcon className={className} />
          },
        },
        // {
        //   label: 'common:subscriptionSettingsTabLabel',
        //   path: configuration.paths.settings.subscription,
        //   Icon: ({ className }: { className: string }) => {
        //     return <CreditCardIcon className={className} />;
        //   },
        // },
      ],
    },
  ],
}

export default NAVIGATION_CONFIG
